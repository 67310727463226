import logo from './logo.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Lynsey Cuthbert Counselling 
        </p>
        <p>
          Contact me: 
          </p>
          <p>
          hello@lynseycuthbertcounselling.co.uk
          </p>
          <p>
          Text: 07340 297245
        </p>
      </header>
    </div>
  );
}

export default App;
